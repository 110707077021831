<template>


<div class="container has-background-white-ter">
    <section id="users-timetable" class="section">
      <div class="container">
        <div class="box" style="align-content: flex-start; flex-direction: row; justify-content: space-between; display: flex;" >

          <h4 class="title is-4 is-spaced "><h4 class="title is-4 is-spaced">My Full Timetable</h4></h4>



            <div class="field has-addons is-pulled-right">
             <p class="control">
              <button class="button" @click="printTimetable()">Print</button>
            </p>




            </div>
          </div>



      </div>
      </section>



      <div class="container">

        <template v-if="formatTable.length">
          <div
            v-for="(day, index) in formatTable"
            :key="day.name"
            class="tile is-ancestor" :style="(day.name === $store.getters.timetableDay) ? 'border: red; border-width: thick; border-style: groove;' : ''"
          >
            <div
              :class="{ 'has-background-primary-light': index % 2 === 0 }"
              class="tile is-parent"
            >
              <article class="media tile is-child box has-background-info" style="border: 1px; border-color: crimson;">
                <div class="media-left is-size-1">
                  <Icon icon="mdi:timetable" class="is-64x64" />
                </div>
                <div class="media-content">
                  <div class="content" style="height: 100%">
                    <p>
                      {{ day.name }}
                    </p>
                  </div>
                </div>
              </article>
            </div>

            <div
              v-for="period in day.periods"
              :key="period.PeriodOrder"
              :class="{ 'has-background-primary-light': index % 2 === 0 }"
              class="tile is-parent"
            >
              <article
                class="tile is-child box"
                :class="
                  period.Group && period.Group.length
                    ? 'has-background-primary'
                    : ''
                "
              >

                <div class="content" style="height: 100%">
                  <p>
                    <span>{{ period.Period }}</span>


                    <br />
                    <small v-if="period.Group && period.Group.length">
                      <router-link  v-if="$store.getters.isStaff && period.groupIdent"  :to="{ path: '/school/timetable/group/'+(period.groupIdent.toString())+'/' }">{{ period.Group }}</router-link>
                      <strong class="" v-else>{{ period.Group }}</strong>
                    </small>

                    <small v-else>Reserved</small>
                    <br />
                    <small>{{ period.RoomName }}</small>
                    <br />

                    <small class="has-text-weight-medium">
                      {{ period.TimeStart }} - {{ period.TimeEnd }}</small
                    >
                  </p>
                </div>
              </article>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="columns">
            <div class="column">
              <div class="box">
                <article class="media">
                  <div class="media-left is-size-1">
                    <Icon icon="mdi:timetable" class="is-64x64" />
                  </div>
                  <div class="media-content">
                    <div class="content">
                      <p>Loading your timetable...</p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </template>
      </div>

  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { Icon } from "@iconify/vue2";
import { format } from "date-fns";
//import h from "helpers/functions";
import tt from "helpers/timetable";


export default {
  name: "home",

  components: {
    Icon,
  },

  data: function () {
    return {
      user: firebase.auth().currentUser,
      timetable: {},
      formatTable: [],
    };
  },
  computed: {
    timetableDay() {
      let date = new Date(this.$store.state.currentTimeTable.data[0].date);
      //console.log(date)
      return format(date, "cccc, do LLL yyyy");
    },

    currentDomain() {
      return this.$store.state.currentDomain;
    },
  },
  methods: {
    test: async function () {
      try {
        alert(JSON.stringify([this.timetable, this.user]));
      } catch (error) {
        alert("Wibble: " + error);
      }
    },

    printTimetable: function () {
      let details = {
        start: this.timetable["start"],
        end: this.timetable["end"],
        user: this.$store.getters.currentUserDetails,
      };

      tt.printTimetable(details, this.formatTable);
    },


  },

  beforeMount() {
    //    console.log([process.env.VUE_APP_API_BASE, 123])





    this.$api.get("v2/" + (this.$store.getters.isStaff ? 'staff' : 'student') +"/me/timetable/", true, true).then((response) => {
      let rtn = [];
      let day = null;
      let dayC = -1;
      let days = [];
      let start = null;
      let end = null;



      this.timetable["raw"] = response;

      for (let session of Object.keys(response)) {
        console.log(session, response[session])
        if (day === null || day !== response[session]["DayName"]) {
          day = response[session]["DayName"];
          days.push(response[session]["DayName"]);
          dayC += 1;

          rtn[dayC] = { name: day, periods: [] };
        }

        if (start === null || start > response[session].TimeStart) {
          start = response[session].TimeStart;
        }

        if (end === null || end < response[session].TimeEnd) {
          end = response[session].TimeEnd;
        }

        rtn[dayC]["periods"].push(response[session]);
      }

      this.timetable["start"] = start;
      this.timetable["end"] = end;

      //console.log(rtn)
      this.formatTable = rtn;
    });
  },
};
</script>
